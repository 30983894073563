import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { loadpredefdata } from '../actions/features/predef';
import { getOriginalWindowSearch } from '../actions/shared/QueryParams';
import { useAppDispatch, useAppSelector } from '../hooks';

export const GetPredef = (): string => {
  const data = queryString.parse(getOriginalWindowSearch()).predef;
  if (Array.isArray(data)) {
    return data[0];
  }
  return data;
};

export const GetPredefInfo = () => {
  const predefString = GetPredef();
  if (!predefString) return null;

  const splits = predefString.split(':');
  if (splits.length < 2) return null;

  return {
    source: splits[0],
    key: splits[1],
  };
};

export const HasPredef = () => {
  return !!GetPredef();
};

const Predef = () => {
  const requiredFieldsLoaded = useAppSelector((state) => state.ensfields.requiredFieldsLoaded);

  // https://app.asana.com/0/1174272655772669/1207305028175193/f
  // Predef darf erst geladen werden, wenn die presetId geladen ist
  const presetIdState = useAppSelector((state) => state.options.presetIdState);

  const [predefLoadStarted, setPredefLoadStarted] = useState(false);

  const dispatch = useAppDispatch();
  const triggerPredefLoad = useCallback(
    (predef, selectedEns) => dispatch(loadpredefdata(predef, selectedEns)),
    [dispatch]
  );

  const triggerNoPredef = useCallback(() => dispatch({ type: 'PREDEF_COMPLETED', notPresent: true }), [dispatch]);

  useEffect(() => {
    const predef = GetPredef();

    if (predefLoadStarted) return;

    if (!predef) {
      triggerNoPredef();
      setPredefLoadStarted(true);
      return;
    }

    if (!requiredFieldsLoaded || presetIdState === '') return;
    setPredefLoadStarted(true);

    triggerPredefLoad(predef, queryString.parse(getOriginalWindowSearch()).selectedEns);
  }, [requiredFieldsLoaded, presetIdState, predefLoadStarted]);

  return null;
};

export default Predef;
