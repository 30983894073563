import React, { useEffect, useMemo } from 'react';
import { MantineProvider } from '@mantine/styles';
import { theme as mtheme } from '@unserkunde/enscompare-components/src/mantine.theme';
import createShades from 'colorshades';
import { useAppSelector } from '@/hooks';
import { getQueryStrings } from '@/actions/shared/QueryParams';

export const shades = (hex) => createShades(hex).colors.map((x) => x.toString());

export default function EnsThemeProvider({ children, ...rest }) {
  const styling = useAppSelector((state) => state.ensfields.ens_styling);

  const primaryColor = window?.ensStyling?.primary || styling?.primary;
  const secondaryColor = window?.ensStyling?.secondary || styling?.secondary;

  const theme = useMemo(
    () => ({
      ...mtheme,
      colors: {
        ...mtheme.colors,
        ...(primaryColor ? { primary: Array.isArray(primaryColor) ? primaryColor : shades(primaryColor) } : null),
        ...(secondaryColor
          ? { secondary: Array.isArray(secondaryColor) ? secondaryColor : shades(secondaryColor) }
          : null),
      },
      components: {
        ...mtheme.components,
        Container: {
          defaultProps: window?.ensOptions?.fullWidth ? { sizes: {} } : undefined,
        },
      },
    }),
    [styling]
  );

  if (getQueryStrings()?.devmode === 'true') {
    useEffect(() => {
      console.log('theme', theme);
    }, [theme]);
  }

  //@ts-ignore
  return (
    <MantineProvider
      //withGlobalStyles
      withNormalizeCSS
      {...rest}
      // @ts-ignore
      theme={theme}>
      {children}
    </MantineProvider>
  );
}
