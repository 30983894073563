import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://fd2e70ef9cabebb870ea23fe1b0be51e@o4506117183766528.ingest.us.sentry.io/4508103150993408',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost' /* , /^https:\/\/ebikeversicherungen\.net/ */],
  denyUrls: [/tiermedizinportal/, /localhost/],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
