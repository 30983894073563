//import './wdyr';

import './sentry';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createEmotionCache } from '@mantine/core';
import { StyleSheetManager } from 'styled-components';

import App from './App';

import './features/NumeralSetup';
import { addInlineStyle, emotionCacheRoot, reactRootElement, stylesRoot } from './indexElements';

import './ViteDynamicContentLoader';

// @ts-ignore
import styles from './styles/index.scss?inline';
// @ts-ignore
import globalStyles from './styles/globals.scss?inline';

import { resetStore } from './store';
import { disableEnsFieldPreload } from './actions/features/ensfields';
import { EnsErrorBoundary } from './forms/shared/EnsErrorBoundary';
import { GetIsDevMode } from './forms/shared/IsDevMode';

const domRootId = window.ensDOMRoot || 'ensurance_view_root';
const domRoot = document.getElementById(domRootId);

const shadowDom = domRoot.attachShadow({ mode: 'open' });

shadowDom.appendChild(stylesRoot);
shadowDom.appendChild(emotionCacheRoot);
shadowDom.appendChild(reactRootElement);

const emotionCache = createEmotionCache({
  container: emotionCacheRoot,
  key: 's',
});

addInlineStyle(styles);
addInlineStyle(globalStyles);

let root = createRoot(reactRootElement);

const ErrorProducer = () => {
  const [error, setError] = React.useState<boolean>(false);

  if (error) throw new Error('Error thrown from Erb');

  return <button onClick={() => setError(true)}>Throw error</button>;
};

const EnsMain = () => {
  return (
    <StrictMode>
      <EnsErrorBoundary>
        <StyleSheetManager target={stylesRoot}>
          <>
            <App
              mantineProviderProps={{
                defaultProps: { Portal: { target: shadowDom } },
                emotionCache: emotionCache,
              }}
            />
            {GetIsDevMode() && <ErrorProducer />}
          </>
        </StyleSheetManager>
      </EnsErrorBoundary>
    </StrictMode>
  );
};

const ensRenderMain = () => root.render(<EnsMain />);
ensRenderMain();

window.ensRerenderWithApiSource = (apiSourceUrl: string) => {
  root.unmount();

  window.ensOverrideHostUrl = apiSourceUrl;
  disableEnsFieldPreload();
  resetStore();

  root = createRoot(reactRootElement);
  root.render(<EnsMain />);
};

if (window.ensRootClass) {
  window.ensRootClass.split(' ').forEach((cl) => reactRootElement.classList.add(cl));
}
