import React, { CSSProperties, useMemo } from 'react';

import Frame from '@unserkunde/enscompare-components/src/components/layout/Frame';
import CompareList from '@unserkunde/enscompare-components/src/components/misc/CompareList';
import { useAppSelector } from '@/hooks';
import { Anchor, Box, Group, List, Paper, createStyles, keyframes } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useCompareScroll } from '@/actions/compare';

const colorCodeMapping = {
  g: 'positive',
  r: 'negative',
  y: 'warning',
};

const bounceMax = 7;

export const bounceRight = keyframes({
  'from, 20%, 53%, 80%, to': { transform: 'translate(0,0)' },
  '40%, 43%': { transform: `translate(${bounceMax}px,0)` },
  '70%': { transform: `translate(${bounceMax / 1.5}px,0)` },
  '90%': { transform: `translate(${bounceMax / 2}px,0)` },
});

export const bounceLeft = keyframes({
  'from, 20%, 53%, 80%, to': { transform: 'translate(0,0)' },
  '40%, 43%': { transform: `translate(${-bounceMax}px,0)` },
  '70%': { transform: `translate(${-bounceMax / 1.5}px,0)` },
  '90%': { transform: `translate(${-bounceMax / 2}px,0)` },
});

const useStyles = createStyles(
  (theme, props: { height?: number; buttonsMode?: 'prev' | 'next' } | undefined = undefined) => ({
    groupContainer: {
      borderColor: theme.colors.gray[4],
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: 0,
      padding: theme.spacing.xs,
      marginLeft: -theme.spacing.xs,
      marginRight: -theme.spacing.xs,
      '&:first-of-type': {
        borderRadius: `${theme.spacing.xs}px ${theme.spacing.xs}px 0 0`,
      },
      '&:last-child': {
        borderRadius: `0 0 ${theme.spacing.xs} ${theme.spacing.xs}`,
      },
      boxShadow: 'inset 0px 8px 7px -3px #00000022',
    },
    frame: {
      gap: 0,
    },
    container: {
      position: 'relative',
    },
    prevNextWrapper: {
      position: 'absolute',
      inset: `${theme.spacing.xl}px 0 ${3 * theme.spacing.xl}px 0`,
    },
    prevNextContainer: {
      zIndex: 10,
      position: 'sticky',
      top: (props?.height || 0) / 2,
      right: '0',
      left: '0',
      height: '0',
      transform: 'translate(0,-200%)',
    },
    prevNextButton: {
      backgroundColor: theme.colors.secondary[5],
      height: 'min(5.5em,12vh)',
      width: '2.5em',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'all',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.secondary[6],
      },
      '> svg': {
        animation: `${props?.buttonsMode === 'next' ? bounceRight : bounceLeft} 2s ease infinite`,
      },
    },
  })
);

export const parseEntry = (
  entry: string,
  options?: {
    ensuranceList: RootState['ensuranceList'];
    fieldName?: string;
    ensName?: string;
  }
) => {
  // Apply documents shortcode
  if (options && options.fieldName === '[documents]') {
    if (!options?.ensuranceList?.list) return {};

    const ens = options.ensuranceList.list.find((e) => e.name === options.ensName);

    if (ens && ens.documents)
      return {
        label: (
          <Box ta={'left'}>
            <List>
              {Object.entries(ens.documents).map((doc) => {
                return (
                  <List.Item key={doc[0]}>
                    <Anchor
                      target={'_blank'}
                      href={doc[1] as string}>
                      {doc[0]}
                    </Anchor>
                  </List.Item>
                );
              })}
            </List>
          </Box>
        ),
      };
  }

  try {
    const splits = entry?.split(/(?<!\\)\//).map((s) => s.replace(/\\\//, '/'));

    const labelRest =
      splits?.length > 3
        ? splits
            .slice(3)
            .map((s) => '/' + s)
            .join('')
        : '';

    return {
      icon: colorCodeMapping[splits?.length >= 1 ? splits[0] : ''],
      label: (splits?.length >= 3 ? splits[2] : '') + labelRest,
      tooltip: splits?.length >= 2 ? splits[1] : '',
    };
  } catch (e) {
    throw new Error('Error parsing entry: "' + entry + '". Original error: ' + e.toString());
  }
};

const mapBadgeProps = (groupPointsEntry: CompareTableEntry, compareSource: string) => {
  if (!groupPointsEntry || !groupPointsEntry[compareSource]) return null;

  const ownPoints = parseInt(groupPointsEntry?.[compareSource]);

  let color = 'green';
  if (ownPoints < parseInt(groupPointsEntry.totalPoints?.toString())) {
    color = 'yellow';
  }
  if (ownPoints == 0) {
    color = 'red';
  }

  return {
    children: ownPoints + ' von ' + groupPointsEntry.totalPoints + ' Punkten',
    smallChildren: ownPoints + ' / ' + groupPointsEntry.totalPoints,
    color: color,
  };
};

const CompareGroupItem = ({
  groupName,
  items,
  onlyDifferences,
  itemIndex,
}: {
  groupName: string;
  items: CompareTableEntry[];
  onlyDifferences: boolean;
  itemIndex: number;
}) => {
  const selectedNames = useAppSelector((state) => state.ensCompare.visibleNames);

  const ensBaseInfo = useAppSelector((state) => state.ensCompare.ensuranceBaseInfo);

  const groupPointsEntry = useMemo(() => items.find((item) => item.key === 'groupPoints'), [items]);

  const infoText = useAppSelector((state) => state.ensCompare.groupInfos[groupName]?.Infotext);
  const infotextTooltip = useAppSelector((state) => state.ensCompare.groupInfos[groupName]?.InfotextTooltip);

  const { classes } = useStyles(undefined);

  const doubleFilteredEntries = useMemo(
    () =>
      items.filter((item) => {
        return (
          !onlyDifferences ||
          new Set(selectedNames.map((ins) => ensBaseInfo[ins].useCompareFrom).map((insName) => item[insName])).size > 1
        );
      }),

    [items, onlyDifferences, ensBaseInfo]
  );

  const compareEntries = useMemo(() => doubleFilteredEntries.filter((e) => e.field), [doubleFilteredEntries]);

  const usedEntries = compareEntries;

  const ensuranceList = useAppSelector((state) => state.ensuranceList);

  const mappedItems = useMemo(() => {
    return selectedNames.map((ins) => {
      const compareSource = ensBaseInfo[ins]?.useCompareFrom;

      return {
        badge: mapBadgeProps(groupPointsEntry, compareSource),
        values: usedEntries.map((compEntry) =>
          parseEntry(compEntry[compareSource], {
            ensName: ins,
            fieldName: compEntry.field,
            ensuranceList,
          })
        ),
        uuid: ins,
      };
    });
  }, [selectedNames, groupPointsEntry, usedEntries, ensBaseInfo, ensuranceList]);

  const compareEntriyLabels = useMemo(
    () =>
      usedEntries.map((e) => {
        if (e.field === '[documents]') return 'Dokumente';

        return e.field;
      }),
    [usedEntries]
  );

  if (usedEntries.length === 0) return null;

  return (
    <Paper className={classes.groupContainer}>
      <CompareList
        showDetails={['Diebstahl', 'Gesamtübersicht', 'Versicherungssummen'].includes(groupName) || itemIndex === 0}
        description={infoText}
        headerTooltip={infotextTooltip}
        items={mappedItems}
        listHeader={compareEntriyLabels}
        title={groupName}
      />
    </Paper>
  );
};

const CompareNavigationButton = (props: { mode: 'prev' | 'next' }) => {
  const { classes } = useStyles({ buttonsMode: props.mode });

  const style: CSSProperties = useMemo(
    () => ({
      borderRadius: props.mode === 'next' ? '200% 0 0 200%' : '0 200% 200% 0',
      filter: `drop-shadow(${props.mode === 'next' ? '-' : ''}2px 1px 2px rgba(0, 0, 0, 0.5))`,
    }),
    [props.mode, 1]
  );

  const compareScroll = useCompareScroll();

  if ((!compareScroll.canMovePrev && props.mode === 'prev') || (!compareScroll.canMoveNext && props.mode === 'next'))
    return <div />;

  return (
    <Box
      style={style}
      onClick={props.mode === 'prev' ? compareScroll.onPrev : compareScroll.onNext}
      className={classes.prevNextButton}>
      {props.mode === 'prev' ? <FaChevronLeft color='white' /> : <FaChevronRight color='white' />}
    </Box>
  );
};

const CompareTableContent = ({ onlyDifferences }) => {
  const compDataGroups = useAppSelector((state) => state.ensCompare.compareData) || {};
  const groupNames = useMemo(() => Object.keys(compDataGroups), [compDataGroups]);

  const { height, width } = useViewportSize();

  const { classes } = useStyles({ height });

  return (
    <div className={classes.container}>
      <Box
        className={classes.prevNextWrapper}
        style={{ pointerEvents: 'none' }}>
        <Group
          className={classes.prevNextContainer}
          position='apart'>
          <CompareNavigationButton mode='prev' />
          <CompareNavigationButton mode='next' />
        </Group>
      </Box>
      <Frame
        px={0}
        className={classes.frame}>
        {groupNames
          .filter((gn) => !!gn)
          //.slice(0, 2)
          .map((groupName, index) => (
            <CompareGroupItem
              key={index}
              itemIndex={index}
              groupName={groupName}
              items={compDataGroups[groupName]}
              onlyDifferences={onlyDifferences}
            />
          ))}
      </Frame>
    </div>
  );
};

export default CompareTableContent;
