import Audit from '@/actions/data/audit';
import React from 'react';
import { detect } from 'detect-browser';

//import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundary } from '@sentry/react';

export const EnsErrorBoundary = (props: React.PropsWithChildren<unknown>) => {
  const [auditRes, setAuditRes] = React.useState(null);

  const onLogError = (error: unknown, stack: string | unknown, eventId: string) => {
    Audit({ error: error.toString(), stack, eventId, browser: detect() }, '0', 'client:error:critical')
      .then((res) => res.json())
      .then((res) => {
        const regRes = /(\d+)/.exec(res);
        if (!regRes || !regRes.length) return;
        setAuditRes(regRes[0]);
      });
  };

  return (
    <ErrorBoundary
      fallback={
        <div role='alert'>
          <p>Es ist ein Fehler aufgetreten. Unsere IT arbeitet an einer Lösung ({auditRes})</p>
        </div>
      }
      onError={onLogError}>
      {props.children}
    </ErrorBoundary>
  );
};
